import { Component, OnInit } from '@angular/core';
import { MatSelectionListChange, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services/authentication.service';
import { ProductService } from '../../services/product.service';
import { LineService } from 'src/app/services/line.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit{
  product: any = {};

  name: string;

  catalogues: any;
  selectedCatalogue: any;

  lines: any;
  selectedLines: any;

  numberOfSections: any;
  sections: any[];

  numberOfProducts: any;
  products: any[];

  promotersOnly: boolean = false;

  selectedPackage: any;
  selectedIcon: any;

  productsList: any;
  selectedProduct: any;

  versionsList: any;
  selectedVersion: any;

  newVersion: boolean = false;

  sameAsLastVersion: boolean = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private lineService: LineService,
  ) {}

  ngOnInit() {
    this.authenticationService.isLoggedInWithRedirect();

    this.productService
      .getCatalogues()
      .subscribe((catalogues: any) => {
        if(catalogues) {
          this.catalogues = catalogues;
        } else {
          this.router.navigateByUrl('/products');
        }
      });


    this.lineService
      .getLines()
      .subscribe((lines: any) => {
        if(lines) {
          this.lines = lines;
          this.lines.forEach((line: any) => { line.selected = false; });
        } else {
          this.router.navigateByUrl('/products');
        }
      });

    this.productService
      .getCompanyProducts()
      .subscribe((products: any) => {
        if(products) {
          this.productsList = products;
        } else {
          this.router.navigateByUrl('/products');
        }
      });

    this.selectedLines = [];
  }

  onSetSections() {
    this.sections = [];
    for (let i = 0; i < this.numberOfSections; i++) {
      this.sections.push({
        name: '',
        slides: ''
      });
    }
  }

  onSetProductsNumber() {
    this.products = [];
    for (let i = 0; i < this.numberOfProducts; i++) {
      this.products.push({ name: '' });
    }
  }

  onLineSelected(event: MatSelectionListChange) {
    const selectedLine = event.option;

    if (selectedLine.selected) {
      this.selectedLines.push(selectedLine.value);
    } else {
      this.selectedLines = this.selectedLines.filter(id => id !== selectedLine.value);
    }
  }

  onPackageChange(event: any) {
    this.selectedPackage = event.target.files[0];
  }

  onIconChange(event: any) {
    this.selectedIcon = event.target.files[0];
  }

  onProductSelected() {
    const product = this.productsList.find(product => product._id === this.selectedProduct);
    this.versionsList = product.versions;
  }

  parseProductInput(type: string) {
    let body: any = {};
    body.name = this.name;
    body.catalogue = this.selectedCatalogue;
    body.lines = this.selectedLines;
    
    for (let section of this.sections) {
      section.slides = Array.from(
        {length: section.numberOfSlides},
        (_, i) => ({ name: (i + 1).toString() })
      );
      delete section.numberOfSlides;
    }

    body.versions = [
      {
        number: 1,
        sections: this.sections,
      }
    ];

    if (type === 'catalogue') {
      body.catalogueProducts = this.products;
      body.promotersOnly = this.promotersOnly;
    }

    const data = new FormData();
    data.append('name', body.name);
    data.append('package', this.selectedPackage);
    data.append('icon', this.selectedIcon);
    data.append('body', JSON.stringify(body));

    return data;
  }

  onUploadProduct() {
    const productData = this.parseProductInput('product');   

    this.productService
      .createProduct(productData)
      .subscribe((product: any) => {
        if(product) {
          this.snackBar.open('Product uploaded successfully', 'Close', {
            duration: 3000
          });
          this.router.navigateByUrl('/products');
        } else {
          this.snackBar.open('Error uploading product', 'Close', {
            duration: 3000
          });
        }
      });
  }

  onUploadCatalogue() {
    const catalogueData = this.parseProductInput('catalogue');

    this.productService
      .createCatalogue(catalogueData)
      .subscribe((catalogue: any) => {
        if(catalogue) {
          this.snackBar.open('Catalogue uploaded successfully', 'Close', {
            duration: 3000
          });
          this.router.navigateByUrl('/products');
        } else {
          this.snackBar.open('Error uploading catalogue', 'Close', {
            duration: 3000
          });
        }
      });
  }

  parseVersionInput() {
    let version = {};
    if (this.newVersion) {
      const chosenProduct = this.productsList.find(product => product._id === this.selectedProduct);
      const lastVersion = chosenProduct.versions[chosenProduct.versions.length - 1];

      if (this.sameAsLastVersion) {
        version = {
          number: lastVersion.number + 1,
          sections: lastVersion.sections
        };
      } else {
        for (let section of this.sections) {
          section.slides = Array.from(
            {length: section.numberOfSlides},
            (_, i) => ({ name: (i + 1).toString() })
          );
          delete section.numberOfSlides;
        }

        version = {
          number: lastVersion.number + 1,
          sections: this.sections
        };
      }
    } else {
      for (let section of this.sections) {
        section.slides = Array.from(
          {length: section.numberOfSlides},
          (_, i) => ({ name: (i + 1).toString() })
        );
        delete section.numberOfSlides;
      }

      version = {
        number: this.selectedVersion,
        sections: this.sections
      };
    }

    return version;
  }

  onUploadVersion() {
    const chosenProduct = this.productsList.find(product => product._id === this.selectedProduct);
    const name = chosenProduct.name;
    const version = this.parseVersionInput();
    const body = {
      name,
      version
    };

    const productData = new FormData();

    productData.append('package', this.selectedPackage);
    productData.append('icon', this.selectedIcon);
    productData.append('name', name);
    productData.append('body', JSON.stringify(body));

    if (this.newVersion) {
      this.productService
        .addVersion(this.selectedProduct, productData)
        .subscribe((product: any) => {
          if(product) {
            this.snackBar.open('New version uploaded successfully', 'Close', {
              duration: 3000
            });
            this.router.navigateByUrl('/products');
          } else {
            this.snackBar.open('Error uploading version', 'Close', {
              duration: 3000
            });
          }
        });
    } else {
      this.productService
        .editVersion(this.selectedProduct, productData)
        .subscribe((product: any) => {
          if(product) {
            this.snackBar.open('Version edited successfully', 'Close', {
              duration: 3000
            });
            this.router.navigateByUrl('/products');
          } else {
            this.snackBar.open('Error uploading version', 'Close', {
              duration: 3000
            });
          }
        });
    }
  }

}