import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';

import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit {
  uploadUrl = this.productService.baseUploadsUrl;
  
  filterForm: FormGroup;
  
  displayedColumns = [
    '#',
    'name',
    'catalogue',
    'numberOfVersions',
    'latestVersion',
    'productFile',
    'productIconFile'
  ];
  productsDisplayed: MatTableDataSource<any>;

  constructor(
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {}
  
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();
    
    this.filterForm = this.formBuilder.group({
      filter: [''],
    });

    this.productService
      .getCompanyProducts()
      .subscribe((products: any) => {
        this.productsDisplayed = new MatTableDataSource(products);
        this.productsDisplayed.filterPredicate = this.filterPredicate;
      });
  }

  filterPredicate = (data: any, filter: string) => {
    const searchString = [
      data.name,
      data.catalogue,
      data.versions.length,
      data.versions[data.versions.length - 1].number,
      data.pathName,
      data.iconPath
    ].join(' ').toLowerCase();
      
    return searchString.includes(filter);
  }

  applyFilter() {
    const filterString = this.filterForm.value.filter.toLowerCase();
    this.productsDisplayed.filter = filterString;
  }

  goToProduct(product: any) {
    this.router.navigateByUrl(`/products/${product._id}`);
  }

  goToUploadProduct() {
    this.router.navigateByUrl('/products/upload');
  }
  
}
