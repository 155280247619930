import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tacitapp ControlPanel';
  isLoggedIn: any;

  icon: string = "keyboard_arrow_right";
  isOpen: boolean = true;
  showText: boolean = false;
  iconAlign: string = "center";
  currentRoute: string = "";
  routesMap = [];

  constructor(public authService: AuthenticationService, public router: Router)
  {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.routesMap['/users'] = "Users";
    this.routesMap['/customer-service'] = "Customer Service";
    this.routesMap['/products'] = "Products";
    this.routesMap['/locations']="Locations";
    this.routesMap['/emails'] = "Emails";
    this.routesMap['/sms'] = "SMS";
    this.routesMap['/lines'] = "Lines";
    this.routesMap['/testimonials'] = "Testimonials";
    this.routesMap['/specialities'] = "Specialities";
    this.routesMap['/call-expirations'] = "Call Expirations";
    this.routesMap['/leave-management-templates'] = "Leave Management Templates";
    this.routesMap['/user-policies'] = "User Policies";

    this.router.events.subscribe((val) => {
        if(this.routesMap[this.router.url])
        {
          this.currentRoute = this.routesMap[this.router.url];
        }
    });
  }

  onMenuButtonClick(): void
  {
    if(this.isOpen)
    {
      this.icon = "keyboard_arrow_left"
      this.showText = true;
      this.iconAlign = 'end';
      this.isOpen = !this.isOpen;
    }
    else
    {
      this.icon = "keyboard_arrow_right"
      this.showText = false;
      this.iconAlign = 'center';
      this.isOpen = !this.isOpen;
    }
  }
}
