import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication.service';
import { ProductService } from '../../services/product.service';
import { LineService } from 'src/app/services/line.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [DatePipe],
})

export class ProductComponent implements OnInit
{
  displayedColumns = ['version', 'sections', 'numberOfSlides']
  product: any;

  selectedPackage: File;
  selectedIcon: File;

  updatedBoth: boolean = false;
  uploadsUrl = this.productService.baseUploadsUrl;
  
  linesSelectionForm: FormGroup;
  lines: any[];

  catalogues: any[];
  cataloguesSelectionForm: FormGroup;

  versionsDisplayed: MatTableDataSource<any>;

  constructor(
    private authService: AuthenticationService,
    private productService: ProductService,
    private lineService: LineService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) {}

  handleVersions() {
    const versions = this.product.versions.map((version: any) => {
      version.numberOfSlides = version.sections.reduce(
        (acc: number, section: any) => acc + section.slides.length, 0
      );
      return version;
    });

    this.versionsDisplayed = new MatTableDataSource(versions);
  }

  ngOnInit(): void {
    this.authService.isLoggedInWithRedirect();
    
    const id = this.route.snapshot.paramMap.get('productId');

    forkJoin([
      this.productService.getProduct(id),
      this.lineService.getLines(),
      this.productService.getCatalogues()
    ]).subscribe((results: any) => {
      this.product = results[0];
      this.lines = results[1];
      this.catalogues = results[2];
      this.handleVersions();
      
      this.lines.forEach((line: any) => {
        if (line.products.includes(this.product._id)) {
          line.selected = true;
        } else {
          line.selected = false;
        }
      });

      this.catalogues.forEach((catalogue: any) => {
        if (this.product.catalogues.includes(catalogue._id)) {
          catalogue.selected = true;
        } else {
          catalogue.selected = false;
        }
      });
    });

    this.linesSelectionForm = this.formBuilder.group({
      lines: this.formBuilder.array([]),
    });

    this.cataloguesSelectionForm = this.formBuilder.group({
      catalogues: this.formBuilder.array([]),
    });
  }
  
  onDelete() {
    this.productService
      .deleteProduct(this.product._id)
      .subscribe((product: any) => {
        if (product) {
          this.snackBar.open('Product deleted successfully', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });
          this.router.navigate(['/products']);
        }
      });
  }

  onPackageChange(event: any) {
    this.selectedPackage = event.target.files[0];
    if (this.selectedIcon) {
      this.updatedBoth = true;
    }
  }

  onIconChange(event: any) {
    this.selectedIcon = event.target.files[0];
    if (this.selectedPackage) {
      this.updatedBoth = true;
    }
  }

  updateFiles() {
    const formData = new FormData();
    formData.append('package', this.selectedPackage);
    formData.append('icon', this.selectedIcon);
    
    const productId = this.product._id;
    
    this.productService
      .updateFiles(productId, formData)
      .subscribe((product: any) => {
        if (product) {
          this.product = product;
          this.snackBar.open('Files updated successfully', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });
        } else {
          this.snackBar.open('Error updating files', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });
        }
      });
  }

  toggleLineSelection(lineId: string) {
    let linesControl = this.linesSelectionForm.get('lines') as FormArray;
  
    const index = linesControl.controls.findIndex(control => control.value === lineId);
  
    if (index === -1) {
      // The line is not currently selected, so add them to the FormArray
      linesControl.push(this.formBuilder.control(lineId));
    } else {
      // The line is currently selected, so remove them from the FormArray
      linesControl.removeAt(index);
    }
  
    // Update the selected property of the line
    const line = this.lines.find(line => line._id === lineId);
    line.selected = !line.selected;
  }

  onUpdateProductLines() {
    const lineIds = this.linesSelectionForm.value.lines;
    const productId = this.product._id;

    this.lineService
      .assignProductToLines(productId, lineIds)
      .subscribe((product: any) => {
        if (product) {
          this.snackBar.open('Product lines updated successfully', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });

          this.product = product;
        } else {
          this.snackBar.open('Error updating product lines', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });
        }
      });
  }

  toggleCatalogueSelection(catalogueId: string) {
    let cataloguesControl = this.cataloguesSelectionForm.get('catalogues') as FormArray;
  
    const index = cataloguesControl.controls.findIndex(control => control.value === catalogueId);
  
    if (index === -1) {
      // The catalogue is not currently selected, so add them to the FormArray
      cataloguesControl.push(this.formBuilder.control(catalogueId));
    } else {
      // The catalogue is currently selected, so remove them from the FormArray
      cataloguesControl.removeAt(index);
    }
  
    // Update the isSelected property of the catalogue
    const catalogue = this.catalogues.find(catalogue => catalogue._id === catalogueId);
    catalogue.isSelected = !catalogue.isSelected;
  }

  updateProductCatalogues() {
    const catalogueIds = this.cataloguesSelectionForm.value.catalogues;

    this.productService
      .updateCatalogues(this.product._id, catalogueIds)
      .subscribe((product: any) => {
        if (product) {
          this.snackBar.open('Product catalogues updated successfully', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });

          this.product = product;
        } else {
          this.snackBar.open('Error updating product catalogues', 'Close', {
            verticalPosition: 'top',
            duration: 2000,
          });
        }
      }); 
  }

}
