// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Product Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class ProductService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/products/`;
  baseUploadsUrl: String = environment.uploadsUrlV2;

  // #=========== ---- =========== Product Service Constructor =========== ---- ===========# //

  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Product Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Get Company Products Method =========== ---- ===========# //
  getCompanyProducts() {
    const token = this.authService.getToken();
    return this.http.get(`${this.baseUrl}get-company-products`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  getProduct(id: string) {
    const token = this.authService.getToken();

    return this.http
      .get(`${this.baseUrl}/get-one/${id}`, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  deleteProduct(id: string) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .delete(`${this.baseUrl}/delete/${companyId}/${id}`, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  getCatalogues() {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .get(`${this.baseUrl}/get-catalogues/${companyId}`, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.catalogues;
          } else {
            return null;
          }
        })
      );
  }

  updateCatalogues(productId: string, catalogueIds: string[]) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .put(
        `${this.baseUrl}/update-catalogues/${companyId}/${productId}`,
        { catalogueIds },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  updateFiles(productId: string, formData: FormData) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .put(`${this.baseUrl}/update-files/${companyId}/${productId}`, formData, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  createProduct(product: any) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .post(`${this.baseUrl}/create/${companyId}`, product, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  createCatalogue(catalogue: any) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .post(`${this.baseUrl}/create-catalogue/${companyId}`, catalogue, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.catalogue;
          } else {
            return null;
          }
        })
      );
  }

  addVersion(productId: any, productData: any) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}/add-version/${companyId}/${productId}`,
        productData,
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }

  editVersion(productId: any, productData: any) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .put(
        `${this.baseUrl}/edit-version/${companyId}/${productId}`,
        productData,
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.product;
          } else {
            return null;
          }
        })
      );
  }
}
